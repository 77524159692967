import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from 'reactstrap'
import { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { uploadUsers } from '../../api'
import Papa from 'papaparse'; // Import PapaParse
import { CSVLink } from 'react-csv'

const ClverTapUploads = ({ showModal }) => {
  const [unprocessedData, setUnprocessedData] = useState([])
  const downloadCSV = (users) => {//for the unprocessed users
    console.log(users);
    
    const csvDataAll = users.map((item) => ({
      "identity": item?.identity,
      "id_name": item?.profileData?.id_name,
      "id_email": item?.profileData?.id_email,
      "id_phone": item?.profileData?.id_phone.slice(1),
      "id_alternatenumber": item?.profileData?.id_alternatenumber.slice(1),
      "Address": item?.profileData?.Address,
      "id_gender": item?.profileData?.id_gender,
      "Course Location": item?.profileData?.['Course Location'],
      "Fees_Residential": item?.profileData?.Fees_Residential,
      "Fees_Non_Residential": item?.profileData?.Fees_Non_Residential,
      "Fees_Online": item?.profileData?.Fees_Online,
      "Course_name": item?.profileData?.Course_name?.['$add']?.join(','),
      "Course_SubType": item?.profileData?.Course_SubType,
      "City": item?.profileData?.City,
      "Nationality": item?.profileData?.Nationality,
      "Date of Completion of TTC": item?.profileData?.['Date of Completion of TTC'] ? epochToString(item?.profileData?.['Date of Completion of TTC']) : '',
      "MSG-Email": item?.profileData?.['MSG-Email'] ? "TRUE" : "FALSE",
      "MSG-SMS": item?.profileData?.['MSG-SMS'] ? "TRUE" : "FALSE",
      "MSG-Whatsapp": item?.profileData?.['MSG-whatsapp'] ? "TRUE" : "FALSE",
      "Lead_Type": item?.profileData?.['Lead_Type'],
      "User_Created_Date":item?.profileData?.['user_created_date'],
      "Error": item?.error
    })
    )
    return csvDataAll
  }

  const handleSubmit = async () => {
    if (csvData.length > 0) {
      let unprocessedData = []
      setIsProgressBar(true)
      const batchSize = 500;//chunk count to upload for an API at one time
      const totalBatches = Math.ceil(csvData.length / batchSize);//create the count for api calls

      for (let i = 0; i < csvData.length; i += batchSize) {
        const batch = csvData.slice(i, i + batchSize);
        // await new Promise((resolve) => setTimeout(resolve, 2000)); // 1 second delay
        try {
          const response = await uploadUsers({ data: JSON.stringify({ "d": batch }) });
          const completedBatches = Math.floor(i / batchSize) + 1;
          const percentage = Math.round((completedBatches / totalBatches) * 100);
          if (response?.data?.unprocessed?.length > 0) {//store all the unprocessed users
            response?.data?.unprocessed?.forEach(user => {
              unprocessedData.push({ ...user?.record, error: user?.error })
            })
          }

          setProgress(percentage);
          setUnprocessedData(unprocessedData);

          if (percentage == 100) {//reset data
            setProgress(0); setIsProgressBar(false); setFileName(''); setCsvData([]); setShowAlert(true)
          }
        } catch (error) {
          setProgress(0);
          setIsProgressBar(false);
          console.error(`Error calling API for batch starting at index ${i}:`, error);
        }
      }

    }

  }
  const [isProgressBar, setIsProgressBar] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [progress, setProgress] = useState(0);
  const progressStyles = {
    container: {
      width: '100%',
      backgroundColor: '#f0f0f0',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',  // Add subtle shadow
    },
    progressBar: {
      height: '30px',
      backgroundColor: '#3b82f6',  // Blue shade
      borderRadius: '10px',
      textAlign: 'center',
      color: 'white',
      transition: 'width 0.6s ease-in-out, background-color 0.4s ease',  // Smooth transition for width and background color
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
      animation: 'pulse 1.5s infinite ease-in-out',  // Pulse animation
    },
    progressText: {
      fontWeight: 'bold',
      zIndex: 1,  // Ensure the text stays on top of the pulse animation
    },
  };

  const [fileName, setFileName] = useState('');
  const [csvData, setCsvData] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      parseCSV(file);
    } else {
      setFileName('');
    }
  };
  // Function to handle CSV parsing
  const parseCSV = (file) => {
    Papa.parse(file, {
      header: true, // Ensures that the CSV headers are used as object keys
      skipEmptyLines: true,
      // transform: (value, header) => {
      //   if (header === 'id_phone' || header === 'identity') { // Replace 'mobile' with your actual header name
      //     return String(value);
      //   }
      //   return value;
      // },
      complete: (result) => {
        let formattedData = []
        result.data?.forEach(user => {
          formattedData.push({
            "identity": user?.identity?user?.identity:user?.id_email,
            "type": "profile",
            "profileData": {
              "id_name": user?.id_name,
              "id_email": user?.id_email,
              "id_phone": user?.id_phone ? `+${user?.id_phone}` : '',
              "Name": user?.id_name,
              "Email": user?.id_email,
              "Phone": user?.id_phone ? `+${user?.id_phone}` : '',
              "id_alternatenumber": user?.id_alternatenumber ? `+${user?.id_alternatenumber}` : '',
              "Address": user?.Address,
              "id_gender": user?.id_gender,
              "Course Location": user?.['Course Location'],
              "Fees_Residential": user?.Fees_Residential,
              "Fees_Non_Residential": user?.Fees_Non_Residential,
              "Fees_Online": user?.Fees_Online,
              "Course_name": { "$add": user?.Course_name?.split(',') },
              "Course_SubType": user?.Course_SubType,
              "City": user?.City,
              "Nationality": user?.Nationality,
              "Date of Completion of TTC": user?.['Date of Completion of TTC'] ? epoch(user?.['Date of Completion of TTC']) : '',
              "MSG-Email": user?.['MSG-Email']?.toUpperCase() === "TRUE",
              "MSG-SMS": user?.['MSG-SMS']?.toUpperCase() === "TRUE",
              "MSG-whatsapp": user?.['MSG-Whatsapp']?.toUpperCase() === "TRUE",
              "Lead_Type": user?.['Lead_Type'],
              "user_created_date":user?.['User_Created_Date']
            }
          })
        });

        setCsvData(formattedData); // Store the parsed CSV data as an array of objects
        console.log(formattedData);

      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const epoch = (indate) => {
    let a = indate;
    let [month, year] = a.split('-');
    // Create a date object for December 1, 2024
    const dateString = `${month} 1, 20${year}`;
    const date = new Date(dateString);
    // Get the epoch time (milliseconds since January 1, 1970)
    const epochTime = date.getTime();
    return epochTime
  }

  const epochToString = (epochTime) => {
    const date = new Date(epochTime);
    // Get the month and year
    const options = { month: 'short', year: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    // Replace the space with a hyphen
    return formattedDate.replace(' ', '-');
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type === 'text/csv') {
      setFileName(file.name);
      parseCSV(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      // height: '100vh',
      justifyContent: 'center',
      cursor: 'pointer'
    },
    uploadContainer: {
      border: '2px dashed #ccc',
      borderRadius: '10px',
      width: '100%',
      height: '200px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      cursor: 'pointer',
      backgroundColor: '#f9f9f9',
    },
    icon: {
      fontSize: '40px',
      color: '#999',
      marginBottom: '10px',
    },
    fileInput: {
      display: 'none',
    },
    note: {
      fontSize: '12px',
      color: '#999',
      marginTop: '10px',
    },
    count: {
      fontSize: '14px',
      color: 'black',
      marginTop: '10px',
    },
    countFailed: {
      fontSize: '14px',
      color: 'red',
      marginTop: '10px',
    },
  };


  return (
    <>
      {showAlert &&
        <SweetAlert
          title="Uploaded users to CleverTap"
          // warning
          // showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Close"
          reverseButtons={true}
          onConfirm={() => setShowAlert(false)}
        ></SweetAlert>
      }
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">Upload CleverTap Users</h3>
                  </div>
                </Col>
              </Row>
              <div style={styles.container}>
                <div
                  style={styles.uploadContainer}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={() => document.getElementById('fileInput').click()}
                >
                  <div style={styles.icon}>&#8682;</div>
                  <p>{fileName ? fileName : 'Drop CSV here'}</p>
                  <input
                    type="file"
                    id="fileInput"
                    style={styles.fileInput}
                    accept=".csv"
                    onChange={handleFileChange}
                  />
                </div>
                <p style={styles.note}>*Only 1 file at a time</p>
                {csvData.length > 0 && <p style={styles.count}>*Total <b> {csvData.length}</b> users found</p>}
                {(!isProgressBar && unprocessedData?.length > 0) && <p style={styles.countFailed}>*Total <b>{unprocessedData?.length}</b> unprocessed users </p>}
              </div>
              {isProgressBar &&
                <div style={progressStyles.container}>
                  <div style={{ ...progressStyles.progressBar, width: `${progress}%` }}>
                    <span style={progressStyles.progressText}>{`${progress}%`}</span>
                  </div>
                </div>}

              {(!isProgressBar && unprocessedData?.length > 0) &&
                <CSVLink
                  data={downloadCSV(unprocessedData)}

                  filename={'unprocessed_users.csv'}
                  className="export-csv-btn"
                >
                  <Button
                    color="primary"
                    className="w-40 mt-4"
                    type="submit"
                    disabled={false}
                  >
                    Download Unprocessed Users CSV
                  </Button></CSVLink>}

              {!isProgressBar && <Button
                color="primary"
                className="w-100 mt-4"
                type="submit"
                disabled={false}
                onClick={() => handleSubmit()}
              >
                Update users to CleverTap
              </Button>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ClverTapUploads
